/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// Plyr Styles
@import '~plyr/dist/plyr.css';

// TOASTR
@import '~ngx-toastr/toastr';

:root {
  //--watermark: url('assets/images/placeholders/logo-rosso-verde.jpg');
  --plyr-color-main: #D97706;
  --plyr-video-control-color-hover: black;
  --plyr-video-control-color: #D97706;
}

html { scroll-behavior: smooth; }

body {
  font-family: 'Urbanist', sans-serif;
}

@layer components {

  .transition-std {
    @apply transition duration-300 ease-in-out;
  }

  .label-std-left {
    @apply text-left block text-xs font-medium text-gray-300 mt-2
  }

  .label-std-left-validated {
    @apply text-left block text-xs font-medium text-gray-300 mt-2 after:content-['*'] after:ml-0.5 after:text-red-500
  }

  .invalid-label {
    @apply text-red-500 text-xs mt-2 text-left
  }

  .input-std-dark {
    @apply bg-customDark autofill:bg-customDark placeholder:text-gray-700 border border-gray-700 text-white mt-1 focus:ring-redCustom shadow-dark focus:border-redCustom hover:bg-customDark transition-std block w-full sm:text-sm rounded-md invalid:border-red-500 focus:invalid:border-red-500 focus:invalid:ring-red-500
  }

  .radio-redCustom {
    @apply inline-flex my-auto bg-customDark ring-0 text-redCustom focus:outline-none focus:ring-0 focus:ring-offset-0 checked:ring-0 cursor-pointer transition-std checked:ring-0 m-auto rounded-full default:bg-customDark checked:bg-redCustom
  }

  .button-small {
    @apply shadow-dark inline-flex rounded-full text-black font-semibold bg-redCustom border-0 py-2 px-8 focus:outline-none hover:bg-redCustom-dark transition duration-300 ease-in-out transform text-sm items-center
  }

  .button-std {
    @apply shadow-dark inline-flex rounded-full text-black font-semibold bg-redCustom border-0 py-2 px-8 focus:outline-none hover:bg-redCustom-dark transition duration-300 ease-in-out transform text-lg items-center
  }

  .label-std-center {
    @apply text-center block text-sm text-gray-300 my-1
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

.plyr__video-wrapper::before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  content: '';
  height: 75%;
  width: 90%;
  /*background: var(--watermark);
  background-repeat: space;
  background-size: 300px auto, auto !important;*/
}


/* Lightbox */

.image-overlay {
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  background-size:cover;
}

// Nasconde i controlli per scorrere la galleria di immagini
.lb-nav {
  display: none;
}

.lightbox {
  background: rgba(0, 0, 0, 0.8);
  display: none;
  position: fixed;
  z-index: 1000;
}

.lightbox:target {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
}

.lightbox img {
  border-radius: 1em;
  display: block;
  margin: auto;
  max-height: 80vh;
  max-width: 90vh;
}


.lightbox .fechar {
  color: #fff;
  font-size: 4em;
  font-weight: bold;
  height: 1em;
  position: fixed;
  right: 0;
  top: 0;
  width: 1em;
  z-index: 1001;
}

.lightbox p {
  font-size: 2em;
  font-weight: bold;
  text-align: center;
}